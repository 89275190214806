import React, { useEffect, useState, useRef } from "react"
import { localidades, puntosDeVenta } from "./mockData"
import loadable from "@loadable/component"
import styles from "../../styles/citymap.module.scss"
import dragIcon from "../../images/quilmesMusica/svg/quilmes-web-16.svg"
import customStyleMap from "./custom.json"
import { greatPlaceStyle } from "./style"
import Pointer from "./Pointer"
import { IsMobileDevice } from "../../../utils/ismobile"
import Geocode from "react-geocode"

const GoogleMapReact = loadable(() => import("google-map-react"))

Geocode.setApiKey("AIzaSyBARPZHLqZGB5Ws-P98HsHLoP9KxWOV13k")
Geocode.setLanguage("es")
Geocode.setRegion("ar")

export default function ElMundialEnTusManos({ province, data }) {
  const [isMobile, setIsMobile] = useState(false)
  const [searchInput, setSearchInput] = useState("")
  const [searchOptions, setSearchOptions] = useState([])
  const [visiblePointers, setVisiblePointers] = useState(data)
  const [pdvs, setPdvs] = useState([])
  const [zoom, setZoom] = useState(7)
  const [showDragContainer, setShowDragContainer] = useState(true)

  const [testCords, setTestCords] = useState({
    center: {
      lat: -31.12312,
      lng: -64.1484,
    },
    zoom: 7,
  })
  const [position, setPosition] = useState({
    lat: province ? provincesList[province][0] : -31.12312,
    lng: province ? provincesList[province][1] : -64.1484,
  })
  const containerRef = useRef(null)
  useEffect(() => {
    setIsMobile(IsMobileDevice())
  }, [])

  const handleClick = () => {
    // containerRef.current.classList.add("animationIcon")
    // setTimeout(() => {
    //   containerRef.current.style.display = "none"
    // }, 1000)
    containerRef.current.classList.add("fade-out")
    setShowDragContainer(false)
  }

  const handleChangeMap = ({ center, zoom }) => {
    setTestCords({
      center: center,
      zoom: zoom,
    })
  }

  const defaultProps = {
    center: {
      lat: province ? provincesList[province][0] : -31.12312,
      lng: province ? provincesList[province][1] : -64.1484,
    },
    zoom: 7,
  }

  return (
    <>
      <div className={styles.container}>
        {/* <div className={styles.overlay}></div> */}
        <div className={styles.googleMapContainer}>
          <div
            style={{
              display: showDragContainer ? "" : "none",
            }}
            ref={containerRef}
            className={styles.dragIconContainer}
            onClick={handleClick}
          >
            <img className={styles.logo} src={dragIcon} alt="icono arrastra" />

            <p>
              ARRASTRA EL MAPA Y <br /> DESCUBRI TODOS LOS EVENTOS
            </p>

            <p
              // onClick={handleClick}
              style={{
                color: "#67E9DB",
                borderBottom: "1px solid rgb(103, 233, 219)",
                cursor: "pointer",
              }}
            >
              OK!
            </p>
          </div>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyBARPZHLqZGB5Ws-P98HsHLoP9KxWOV13k",
            }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            // style={greatPlaceStyle}
            center={position}
            zoom={zoom}
            options={{ styles: customStyleMap }}
            onChange={handleChangeMap}
          >
            {visiblePointers.map(visiblePointer => {
              return (
                <Pointer
                  key={visiblePointer.titulo}
                  lat={visiblePointer.latitud}
                  lng={visiblePointer.longitud}
                  zoom={zoom}
                  visiblePointer={visiblePointer}
                  provincia={province}
                />
              )
            })}
          </GoogleMapReact>
        </div>
      </div>
    </>
  )
}

// comoLlegar: "https://goo.gl/maps/em5yR5qZVrWmXPn99"
// fecha['2023-02-21', '2023-02-29']
// fondo1
// :
// ""
// fondo2
// :
// ""
// id
// :
// "x8KkDwCrnNWXgfWrhVKZ"
// imgBack
// :
// "https://firebasestorage.googleapis.com/v0/b/webquilmes-1330e.appspot.com/o/PRUEBAS%2F1267.jpg?alt=media&token=7764c164-ae06-4ab7-8ad7-cb40b77c9561"
// latitud
// :
// "-31.2384888"
// localidad
// :
// "Cosquín"
// longitud
// :
// "-64.4639251"
// month
// :
// "febrero"
// provincia
// :
// "Córdoba"
// texto
// :
// "Del 21 al 29 de enero en Cosquín – Córdoba Obtené más información en https://aquicosquin.com.ar/programacion-62-festival-nacional-del-folklore/ "
// titulo
// :
// "Cosquín Folklore"

const customStyleMap2 = [
  {
    featureType: "administrative",
    elementType: "labels",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "poi",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "road",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "transit",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "water",
    stylers: [{ visibility: "off" }],
  },
]

const provincesList = {
  buenos_aires: [-34.60368, -58.38156],
  catamarca: [-28.46957, -65.78524],
  chaco: [-27.46056, -58.98389],
  chubut: [-43.30016, -65.10228],
  cordoba: [-31.4135, -64.18105],
  corrientes: [-27.46784, -58.8344],
  entre_rios: [-31.77467, -60.49565],
  formosa: [-26.18489, -58.17313],
  jujuy: [-24.19457, -65.29712],
  la_pampa: [-36.61667, -64.28333],
  la_rioja: [-29.41105, -66.85067],
  mendoza: [-32.89084, -68.82717],
  misiones: [-27.36708, -55.89608],
  neuquen: [-38.95161, -68.0591],
  rio_negro: [-40.7344, -66.6176],
  salta: [-24.7859, -65.41166],
  san_juan: [-31.5375, -68.53639],
  san_luis: [-34.66627, -58.72927],
  santa_cruz: [-51.62261, -69.21813],
  santa_fe: [-31.64881, -60.70868],
  santiago_del_estero: [-27.79511, -64.26149],
  tierra_del_fuego: [-54.81084, -68.31591],
  tucuman: [-26.82414, -65.2226],
}
